<script>
import { wpService } from "@/services/wp";
import _concat from "lodash/concat";
import mockMembers from "@/mock/users";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    org_id: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
      },
      members: null,
    };
  },

  methods: {
    queryMembers() {
      wpService.organizations.queryOrgMembers(this.org_id).then(members => {
        this.members = _concat(members, mockMembers);
      });
    },
  },

  mounted() {
    this.queryMembers();
  },
};
</script>

<template>
  <div class="members__outer">
    <appLoading v-if="!members" />
    <swiper v-if="false" ref="swiper" class="swiper" :options="swiperOption">
      <swiper-slide
        class="member__outer"
        v-for="(entry, index) in members"
        :key="index"
      >
        <div class="member__inner">
          <div class="user_avatar">
            <img v-if="!entry.user_avatar" src="@/assets/avatar-square.svg" />
            <img
              v-else
              :src="entry.user_avatar.src"
              :srcset="entry.user_avatar.srcset"
            />
          </div>
          <div class="user_info">
            <div class="user_name">
              {{ entry.user_name_full }}
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="members__inner">
      <div class="member__outer" v-for="(entry, index) in members" :key="index">
        <div class="member__inner">
          <div class="user_avatar">
            <img v-if="!entry.user_avatar" src="@/assets/avatar-square.svg" />
            <img
              v-else
              :src="entry.user_avatar.src"
              :srcset="entry.user_avatar.srcset"
            />
          </div>
          <div class="user_info">
            <div class="user_name">
              {{ entry.user_name_full }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.members__outer {
  position: relative;
}
img {
  width: 100%;
}
.members__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  .user_name {
    font-size: 0.875rem;
    font-weight: 600;
    color: #111;
    margin-top: 4%;
  }
  .user_avatar {
    border-radius: 3px;
    overflow: hidden;
    img {
      display: block;
    }
  }
  @media (min-width: 414px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3vw;
  }
  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 860px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2rem;
  }
}
</style>
